<template>
  <div>
    <v-title title="Edit Configuration"></v-title>
    <title-box>
      <div class="row">
        <div class="col col-8">
          <h1>Edit Configuration</h1>
        </div>
      </div>
    </title-box>
    <div class="container-fluid">
      <div class="mt-4">
        <mercur-card>
          <div class="c-configurations">
            <configuration-content :is-editing="true" />
          </div>
        </mercur-card>
      </div>
    </div>

  </div>
</template>

<script>

import ConfigurationContent from '@/components/elements/product-feed/ConfigurationContent'

export default {
    name: 'EditConfiguration',
    components: { ConfigurationContent },
}
</script>

<style lang="scss" scoped>
  .c-configurations {
    padding: 50px;
  }
</style>
